export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyDDkMZcnPUGpMdeg22Kxs9UkPC9s_W4ukk",
    authDomain: "tait-digital.firebaseapp.com",
    databaseURL: "https://tait-digital-default-rtdb.firebaseio.com",
    projectId: "tait-digital",
    storageBucket: "tait-digital.appspot.com",
    messagingSenderId: "456737855324",
    appId: "1:456737855324:web:629fa23dcc5c32fbc65399",
    measurementId: "G-XZFWGHBHKB"
  },
  dataAPI: 'http://localhost:8100/assets/data/'
};
